.rentbook-view-container {
	padding: 20px;
	background-color: #f5f5f5;
	border-radius: 8px;
	max-width: 800px;
	margin: 0 auto;
}

.rentbook-view-container .rent-book-address-header {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
    text-align: center;
}

.rentbook-view-container .radio-content{
    margin-left: 20px;
}

.rentbook-view-container .rent-book-add-address {
	margin-top: 10px;
	width: 100%;
}

.rentbook-view-container .address-list {
	background-color: #fff;
	border-radius: 8px;
	padding: 10px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.rentbook-view-container .rent-book-address-item {
	display: flex;
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid #e8e8e8;
}

.rentbook-view-container .rent-book-address-item:last-child {
	border-bottom: none;
}

.rentbook-view-container .rent-book-radio {
	width: 100%;
}

.rentbook-view-container .city,
.rentbook-view-container .state,
.rentbook-view-container .pincode {
	font-size: 14px;
	color: #333;
    margin-bottom: 10px;
}

.rentbook-view-container pre {
	font-size: 14px;
	white-space: pre-wrap;
}

.rentbook-view-container .rent-book-rent-button{
    font-size: 16px;
    padding: 5px 40px;
    height: 40px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.rentbook-view-container .rent-book-input {
	width: 100%;
}

.offerManager-table {
    border-radius: 12px;
    padding: 24px;
    margin-top: 24px;
    transition: box-shadow 0.3s ease-in-out;
}

.offerManager-table .ant-table-thead > tr > th {
	background-color: var(--color-secondary);
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	padding: 10px 8px;
}

.offerManager-table .ant-table-pagination.ant-pagination {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

.offerManager-addButton {
    margin-bottom: 24px;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    padding: 10px 20px;
    border-radius: 6px;
    transition: background-color 0.3s ease;
    float: right;
}

.offerManager-addButton:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
}

.offerManager-deleteButton {
    color: #ff4d4f;
    transition: color 0.3s ease, transform 0.2s ease;
}

.offerManager-deleteButton:hover {
    color: #ff7875;
    transform: scale(1.05);
}

.offerManager-modal .ant-modal-title {
    font-size: 1.6rem;
    font-weight: 600;
    color: #333;
}

.offerManager-modal .ant-modal {
    border-radius: 10px;
    padding: 16px;
}

.offerManager-modal .ant-modal-content {
    border-radius: 10px;
    padding: 20px;
    background-color: #f9f9f9;
}

.offerManager-modal .ant-modal-footer {
    border-top: none;
}

.offerManager-modal .ant-modal-footer .ant-btn {
    width: 120px;
    height: 40px;
    border-radius: 8px;
    font-size: 16px;
}

.offerManager-modal .ant-modal-footer .ant-btn-primary {
    background-color: #52c41a;
    border-color: #52c41a;
    transition: background-color 0.3s ease;
}

.offerManager-modal .ant-modal-footer .ant-btn-primary:hover {
    background-color: #73d13d;
    border-color: #73d13d;
}

.offerManager-form {
    margin-top: 16px;
}

.offerManager-formItem .ant-form-item-label > label {
    font-weight: 600;
    color: #595959;
}

.offerManager-formItem .ant-input {
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    padding: 10px;
    font-size: 15px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.offerManager-formItem .ant-input:focus,
.offerManager-formItem .ant-input:hover {
    border-color: #40a9ff;
    box-shadow: 0 0 8px rgba(24, 144, 255, 0.3);
}

.offerManager-formItem .ant-select-selector {
    border-radius: 6px;
    padding: 8px;
    font-size: 15px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.offerManager-formItem .ant-select-selector:hover {
    border-color: #40a9ff;
}

.offerManager-formItem .ant-select-selection-placeholder {
    color: #bfbfbf;
    font-style: italic;
}

.offerManager-table .ant-table-tbody > tr:hover > td {
    background-color: #fafafa;
}

.offerManager-table .ant-table-tbody > tr:nth-child(odd) > td {
    background-color: #f5f5f5;
}

@media only screen and (max-width: 768px) {
    .offerManager-addButton {
        width: 100%;
        margin-bottom: 16px;
        padding: 12px 0;
    }

    .offerManager-table {
        padding: 12px;
        margin-top: 16px;
    }

    .offerManager-modal .ant-modal-footer .ant-btn {
        width: 100%;
        margin-bottom: 10px;
    }

    .offerManager-modal .ant-modal-title {
        font-size: 1.4rem;
    }

    .offerManager-formItem {
        margin-bottom: 12px;
    }

    .offerManager-formItem .ant-input {
        padding: 10px;
        font-size: 14px;
    }

    .offerManager-formItem .ant-select-selector {
        padding: 10px;
        font-size: 14px;
    }
}

.books {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	margin: 20px;
	padding: 20px;
	align-items: center;
	justify-content: flex-start;
}

.admin-book-detail {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.admin-book-detail .title {
	color: var(--color-primary);
	font-size: 32px;
	font-weight: 600;
}

.admin-book-detail .author,
.admin-book-detail .available-count {
	font-size: 16px;
}

.admin-book-detail .author span,
.admin-book-detail .available-count span {
	font-weight: 600;
}

.admin-book-detail .desc {
	margin: 20px;
	text-align: justify;
	font-size: 16px;
	opacity: 0.7;
}


.book {
	width: 250px;
	height: 300px;
	padding: 20px;
	background-color: #eee;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.book img {
	margin-top: 20px;
	width: 100px;
	align-items: self-start;
	height: auto;
}

.book-btn {
	display: flex-start;
}

.add-books-main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin: 20px;
	padding: 20px;
}


.book-admin-actions {
	display: flex;
	gap: 20px;
}

@media (max-width: 600px) {
	.admin-book-detail {
		flex-direction: column;
	}
	.admin-book-detail .desc {
		margin: 5px;
		text-align: justify;
		font-size: 16px;
		opacity: 0.7;
	}
}

.activity-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.activity-container .activity-card {
    width: 100%;
    max-width: 600px;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background-color: #fff;
}

.activity-container .form-title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
    color: var(--color-secondary);
}

.activity-container .form-item {
    margin-bottom: 16px;
}

.activity-container .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 500;
    color: #555;
}

.activity-container .ant-input, .ant-input-textarea {
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
}

.activity-container .upload-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 20px;
    color: var(--color-secondary);
    cursor: pointer;
    transition: all 0.3s ease;
}

.activity-container .upload-button:hover {
    border-color: var(--color-secondary);
    color: var(--color-secondary);
}

.activity-container .submit-button {
    width: 100%;
    background-color: var(--color-secondary);
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.activity-container .submit-button:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

@media (max-width: 768px) {
    .activity-container .activity-card {
        padding: 20px;
        max-width: 100%;
    }
}

.login-loginpage {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 25px;
	align-items: center;
}
.login-loginpage h1 {
	text-align: center;
	color: orange;
	margin-top: -10px;
}

.login-submit-btn {
	width: 100%;
	margin-top: -10px;
	background-color: var(--color-secondary);
	border-radius: 5px;
}
.login-forgot-link {
	display: flex;
	justify-content: flex-end;
	color: var(--color-secondary);
	margin-bottom: 10px;
	margin-top: -20px;
	font-size: 12px;
}
.login-forgot-link a {
	color: var(--color-secondary);
}
.login-loginpage .ant-input {
	background-color: #f0f2f3;
}
.login-loginpage .form {
	margin: 50px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border-radius: 7px;
	padding: 20px;
	border-radius: 10px;
	width: 400px;
	margin-left: 100px;
}

.change-pass-form .form {
	background-color: white;
	border-radius: 7px;
	padding: 20px;
	border-radius: 10px;
	max-width: 400px;
	min-width: 50vw;
}

.change-pass-form {
	display: flex;
	align-items: center;
	justify-content: center;
}
.login-signup-btn {
	text-align: center;
}
.login-signup-btn a {
	color: #0184b8;
}

.login-girl-img {
	margin-top: 60px;
	display: flex-end;
	width: 500px;
	height: 500px;
}

@media (max-width: 768px) {
	.login-loginpage {
		margin-top: 0px;
		padding: 10px;
		flex-direction: column-reverse;
	}
	.login-loginpage .form {
		width: calc(100vw - 32px);
		margin: 16px auto;
	}
	.login-girl-img {
		max-width: 300px;
		height: auto;
	}
}

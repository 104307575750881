.subscriptions-container {
	/* max-width: 1200px; */
	margin: 0 auto;
	padding: 20px;
}

.all-plans-title {
	font-size: 40px;
	font-weight: 500;
	text-align: center;
	color: #000;
	margin-bottom: 20px;
	padding: 10px;
	border-radius: 8px;
}

.current-plan-details {
	background-color: #fff5e6;
	padding: 16px;
	border-radius: 8px;
	margin-bottom: 16px;
}

.current-plan-details div {
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
}

.current-plan-details div p {
	font-size: 16px;
}

.current-plan-details div p span {
	font-weight: 600;
}

.selected-plan-details {
	background-color: var(--color-secondary);
	padding: 16px;
	border-radius: 8px;
	margin-top: 16px;
}

.selected-plan-details div {
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
}

.selected-plan-details h3 {
	color: white;
	font-size: 24px;
	font-weight: 500;
	margin: 0;
	padding: 0;
}

.selected-plan-details div p {
	font-size: 16px;
	color: white;
}

.selected-plan-details div p span {
	font-weight: 600;
}

.selected-plan-details button {
	height: 40px;
	background-color: rgb(250, 250, 250, 0.3);
	font-size: 16px;
	margin: 20px auto;
}

.tabs-container {
	display: flex;
	justify-content: space-around;
	margin-bottom: 20px;
}

.tab-item {
	flex: 1;
	text-align: center;
	padding: 15px 10px;
	cursor: pointer;
	background-color: #ccc;
	color: #000;
	border-radius: 8px 8px 0 0;
	margin-right: 2px;
}

.tab-item.active {
	background-color: var(--color-secondary);
	color: #fff;
}

.plans-table {
	width: 100%;
	overflow-x: auto;
}

.subscriptions-container table {
	width: 100%;
	border-collapse: collapse;
	background-color: #fff;
}

.subscriptions-container th,
.subscriptions-container td {
	padding: 10px 20px;
	text-align: center;
	border: 1px solid #ccc;
}

.subscriptions-containerth {
	background-color: #f5f5f5;
}

.subscriptions-container .current-plan {
	background-color: #eaffea;
}

.subscriptions-container .current-plan td {
	font-weight: bold;
}

.subscriptions-container .radio-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.subscriptions-container .radio-container input[type="radio"] {
	cursor: pointer;
}

@media (max-width: 768px) {
	.plans-table {
		width: calc(100vw - 32px);
	}
	.tab-item {
		padding: 16px 4px;
		font-size: 12px;
	}
	.all-plans-title {
		font-size: 24px;
	}
	.current-plan-details div p {
		font-size: 14px;
		padding: 0;
		margin: 4px;
	}
	.selected-plan-details div {
		margin-bottom: 16px;
	}
	.selected-plan-details div p {
		font-size: 16px;
		padding: 0;
		margin: 4px;
	}
	.selected-plan-details button {
		margin: 16px auto;
	}
}

.books-container {
	/* display: flex;
	flex-direction: row; */
	/* justify-content: center; */
	width: 100%;
	min-height: 75vh;
	background-color: #fff;
}

.books-container {
	padding: 10px;
}

.skeleton-container {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	gap: 16px;
	padding: 20px;
}

.skeleton-item {
	display: flex;
	flex-direction: column;
	gap: 8px;
}


.books-container h3 {
	font-weight: 500;
	padding-bottom: 5px;
	border-bottom: 1px solid #ddd;
}

.books-container .filters {
	margin-left: 16px;
	display: flex !important;
	flex-direction: row !important;
	gap: 10px;
	text-transform: capitalize;
}

.filters label {
	display: block !important;
}

.books-container .ant-input {
	background-color: #ffffff;
}

.books-container .filters .filter {
	font-size: 16px;
	display: block;
}

.books-container .book-list {
	padding: 20px;
}

.reviews-user h2 {
	color: var(--color-secondary);
	text-align: center;
	font-size: 32px;
	font-weight: 500;
}

.books-container .book-grid {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	align-items: stretch;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
}

.books-container .book-item {
	border-radius: 5px;
	overflow: hidden;
	min-height: 220px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.books-container .book-item:hover {
	cursor: pointer;
}

.books-container .book-image {
	position: relative;
	left: 30px;
	height: 180px;
	width: 160px;
}

.books-container .book-image img {
	height: 180px;
	width: auto;
	max-width: 156px;
	object-fit: contain;
	filter: drop-shadow(1px 1px 2px rgba(0, 0, 236, 0.25));
}

.books-container .book-details {
	padding: 10px 50px;
	max-width: calc(100% - 150px);
	display: flex;
	flex-direction: column;
	gap: 15px;
	justify-content: space-between;
	height: 100%;
}

.books-container .book-title {
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 500;
}

.books-container .desc {
	text-align: justify;
	font-size: 13px;
}

.books-container .book-author {
	font-size: 14px;
	margin-bottom: 8px;
	opacity: 0.7;
	color: var(--color-secondary);
}

.books-container .book-actions {
	display: flex;
	margin-top: -5px;
	flex-direction: row;
	justify-content: space-between;
}

.books-container .book-actions button {
	color: #fff;
	background-color: var(--color-secondary);

	height: 40px;
}

.books-container .book-actions button:last-child {
	color: rgb(255, 135, 155);
	background-color: transparent;
	border: none;
	width: 40px;
	box-shadow: none;
}

.books-container .book-actions button svg {
	font-size: 24px;
}

.books-container .book-actions button:last-child:hover {
	color: pink;
	background-color: transparent;
	border: none;
	width: 40px;
	box-shadow: none;
}

.books-container .book-pagination {
	text-align: center;
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.books-container .book-pagination li {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-btn-link {
	font-size: 14px;
	color: #1890ff;
}

.book-details-main {
	padding: 20px;
	border-radius: 8px;
	max-width: 80%;
	margin: 20px auto;
}

.bd-main {
	background-color: #fff;
	padding: 20px;
}

.book-details-main .book-details-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.book-details-main .book-image-container {
	max-width: 35vw;
}

.book-details-main .book-image-container .book-image {
	width: 100% !important;
}

.book-details-main .book-info-container {
	flex: 2;
	padding: 20px;
	display: flex;
	flex-direction: column;
}

.book-details-main .book-title {
	font-size: 32px;
	text-align: left;
	font-weight: 600;
	text-transform: capitalize;
	margin-bottom: -5px;
	color: var(--color-secondary);
}

.book-details-main .book-author {
	font-size: 16px;
	font-weight: 400;
	margin-bottom: -2px;
	border-left: 5px solid #ddd;
	padding-left: 10px;
}

.book-details-main .book-description {
	font-size: 16px;
	text-align: justify;
	margin: 20px 0;
	opacity: 0.8;
	white-space: break-spaces;
	word-break: auto-phrase;
	word-wrap: break-word;
}

.book-details-main .book-actions {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.book-details-main .wishlist-button {
	float: right;
	margin-left: -50px;
	margin-right: 10px;
	margin-top: 10px;
	color: var(--color-primary);
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 10px;
	border: none;
	width: 40px;
}

.book-details-main .wishlist-button:hover {
	color: white !important;
	background-color: var(--color-primary) !important;
	border: none;
	width: 40px;
}

.book-details-main .cart-button {
	margin-right: 10px;
	background-color: #0184b8;
	color: white;
	font-size: 18px;
	height: 50px;
	padding: 5px 50px;
	border-radius: 50px;
	border: none;
}

.book-details-main .cart-button:hover {
	margin-right: 10px;
	color: #0184b8;
	font-size: 18px;
	height: 50px;
	padding: 5px 50px;
	border-radius: 50px;
	border: 1px solid #0184b8;
}

.book-details-main .go-cart-button {
	margin-right: 10px;
	color: #0184b8;
	font-size: 18px;
	height: 50px;
	padding: 5px 50px;
	border-radius: 50px;
	border: 1px solid #0184b8;
}

@media (max-width: 768px) {
	.books-container {
		flex-direction: column;
	}

	.books-container .filters {
		margin-left: 8px !important;
		gap: 0px !important;
	}
	.books-container .filters .filter {
		font-size: 14px !important;
	}

	.books-container .book-item {
		flex-direction: column;
		padding: 8px;
	}

	.books-container .book-image {
		left: 0;
	}
	.books-container .book-details {
		padding: 0px;
        width: 100%;
        max-width: none;
	}
	.book-details-main {
		padding: 20px;
		border-radius: 8px;
		width: 100%;
		max-width: 100%;
	}
	.book-details-main .book-details-container {
		flex-direction: column;
		align-items: center;
	}

	.book-details-main .book-image-container {
		width: max-content;
	}

	.book-details-main .book-info-container {
		align-items: center;
		text-align: center;
		padding: 20px 0px;
	}
	.book-details-main .book-description {
		width: 100%;
	}
}

.user-cart-container {
	padding: 20px;
	max-width: 800px;
	background-color: #f9f9f9;
	margin: 0 auto;
}

.user-cart-item {
	background-color: #fff;
	border-radius: 8px;
	margin-bottom: 16px;
	padding: 16px !important;
	transition: box-shadow 0.3s;
}

.user-cart-item:hover {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.user-cart-item-image {
	width: 60px;
	height: 90px;
	object-fit: cover;
	border-radius: 4px;
}

.user-cart-item-title {
	font-size: 18px;
	font-weight: 500;
	color: #333;
}

.user-cart-item-author {
	font-size: 14px;
	color: #888;
}

.user-cart-remove-button {
	color: #ff4d4f;
}

.user-cart-remove-button:hover {
	color: #ff7875;
}

.custom-container {
	display: flex;
	flex-direction: row;

}

.custom-menu {
	
	width: 300px;
	
}

.custom-content {
	flex: 1;
	padding: 16px;
	margin-left: 0px;
}

.custom-menu.ant-menu-light .ant-menu-item-selected {
	background-color: var(--color-secondary) !important;
	color: white;
}

@media (min-width: 769px) {
	.custom-menu {
		border-right: 1px solid #f0f0f0;
	}
}

@media (max-width: 768px) {
	.custom-container {
		flex-direction: column;
	}

	.books-container .book-list{
		padding:0;
	}

	.books-container .book-grid{
		grid-template-columns: 1fr;
	}

	.custom-menu {
		width: 100%;
		border-bottom: 1px solid #f0f0f0;
		display: flex;
		justify-content: center;
		height: max-content;
		/* margin: 5px; */
		/* background-color: transparent; */
		padding: 8px;
		z-index: 99;
	}

	.custom-menu .ant-menu-item {
		/* flex: 1; */
		text-align: center;
	}

	.custom-content {
		padding: 16px;
		margin-left: 0;
		margin-top: 0px;
	}
}

.mobile-filter-btn {
	width: 100%;
	margin: 10px;
	display: flex;
	justify-content: space-between;
	gap: 8px;
}

@media (max-width: 768px) {
	.mobile-filter-btn {
		width: 100%;
		margin : 0;
		margin-bottom:12px;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	.mobile-filter-btn .ant-input-group-wrapper {
		max-width: 400px;
	}
}

.mobile-filter-btn .ant-input-group-wrapper {
	/* max-width: 800px; */
}

.mobile-filter-btn input {
	height: 40px !important;
	width: 100%;
}

.mobile-filter-btn .ant-select-selector {
	height: 40px !important;
	width: 100% !important;
}

.mobile-filter-btn .ant-select {
	height: 40px !important;
	width: 100% !important;
	max-width: 400px;
}

.mobile-filter-btn button {
	height: 40px !important;
}

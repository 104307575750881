body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

* {
	font-family: "Poppins";
}

:root {
	--font-family-primary: "Mulish", sans-serif;
	--font-family-secondary: "lexend", sans-serif;
	--color-primary: #f2002f;
	--color-white: #ffffff;
	--color-secondary: #0184B8;
	--color-bg: #f9f9f9;
}

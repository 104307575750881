.admin-subs-main .ant-modal {
	border-radius: 10px;
	overflow: hidden;
	padding: 20px;
}

.admin-subs-main {
	background-color: #fff;
	padding: 24px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	border-radius: 16px;
}

.admin-subs-main .ant-modal-header {
	background-color: #fafafa;
	border-bottom: none;
	border-radius: 10px 10px 0 0;
	text-align: center;
}

.admin-subs-main .ant-modal-title {
	font-size: 22px;
	font-weight: bold;
}

.admin-subs-main .ant-modal-footer {
	border-top: none;
	text-align: center;
}

.admin-subs-main .ant-btn-primary {
	background-color: green;
	border-color: green;
	border-radius: 8px;
	padding: 8px 20px;
	font-weight: 600;
	transition: all 0.3s ease;
}

.admin-subs-main .ant-btn-primary:hover {
	background-color: green !important;
	border-color: green !important;
}

.admin-subs-main .ant-btn-link {
	color: var(--color-secondary);
}

.admin-subs-main .ant-btn-link:hover {
	color: #fff !important;
	background-color: var(--color-primary) !important;
}

.admin-subs-main .ant-form-item-control {
	margin-bottom: 16px;
}

.admin-subs-main input,
.admin-subs-main .ant-select-selector {
	padding: 10px;
	font-size: 16px;
	border-radius: 6px;
}

.admin-subs-main .ant-input {
	height: 50px;
}

.admin-subs-main .ant-select {
	width: 100%;
	height: 40px;
}

.admin-subs-main .ant-form-item-label > label {
	font-weight: 600;
}

.admin-subs-main .ant-table-thead > tr > th {
	background-color: var(--color-secondary);
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	padding: 10px 8px;
}

.admin-subs-main .ant-table-tbody > tr > td {
	font-size: 14px;
	padding: 10px 8px;
}

.admin-subs-main .ant-table-pagination.ant-pagination {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

@media (max-width: 767px) {
	.admin-subs-main .ant-table {
		font-size: 12px;
	}

	.admin-subs-main .ant-table-thead > tr > th {
		font-size: 14px;
		padding: 12px;
	}

	.admin-subs-main .ant-table-tbody > tr > td {
		font-size: 12px;
		padding: 8px 5px;
	}

	.admin-subs-main .ant-table-wrapper {
		overflow-x: auto;
	}

	.admin-subs-main .ant-table {
		display: block;
		width: 100%;
		overflow-x: scroll;
	}

	.admin-subs-main .ant-btn {
		font-size: 14px;
		padding: 6px 12px;
		margin-bottom: 10px;
	}
}

.admin-subs-main .add-plan-btn {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}

.admin-subs-main .add-plan-btn .ant-btn {
	background-color: #52c41a;
	border-color: #52c41a;
	font-size: 16px;
	font-weight: 600;
	border-radius: 8px;
}

.admin-subs-main .add-plan-btn .ant-btn:hover {
	background-color: #73d13d;
	border-color: #73d13d;
}

@media (max-width: 767px) {
	.admin-subs-main .ant-modal {
		width: 95% !important;
		padding: 15px;
	}

	.admin-subs-main .ant-modal-title {
		font-size: 18px;
	}

	.admin-subs-main .ant-modal-body {
		padding: 15px;
	}

	.admin-subs-main .ant-btn {
		width: 100%;
		margin-bottom: 10px;
	}
}

.admin-subs-main .action-buttons {
	display: flex;
	justify-content: space-around;
}

.admin-subs-main .action-buttons .ant-btn {
	font-size: 14px;
}

.admin-subs-main .action-buttons .ant-btn-danger {
	color: #ff4d4f;
}

.admin-subs-main .action-buttons .ant-btn-danger:hover {
	color: #ff7875;
}

.admin-subs-main .ant-input:focus,
.admin-subs-main .ant-select-selector:focus {
	border-color: #1890ff;
	box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.custom-menu-head {
	background-color: #ffffff;

	/* overflow: hidden; */
}

@media (max-width: 768px) {
	.custom-menu-head {
		display: none;
	}
}

.menu-container {
	display: flex;
	justify-content: flex-start;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 20px;
}

.menu-item {
	position: relative;
	display: flex;
	align-items: center;
	padding: 15px;
	color: #333;
	text-decoration: none;
	font-size: 16px;
	span {
		width: max-content;
	}
}

.menu-item:hover {
	background-color: #f0f0f0;
}

.menu-button {
	display: flex;
	align-items: center;
	background: none;
	border: none;
	cursor: pointer;
	font-size: 16px;
	color: #333;
}

.dropdown-arrow {
	width: 20px;
	height: 20px;
	margin-left: 5px;
	fill: currentColor;
}

.dropdown {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #ffffff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	z-index: 1000;
}

.dropdown-content {
	min-width: 200px;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 10px 15px;
	text-align: left;
	background: none;
	border: none;
	font-size: 14px;
	color: #333;
	cursor: pointer;
}

.dropdown-item:hover {
	background-color: #f0f0f0;
}

.dropdown-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 10px 15px;
	text-align: left;
	background: none;
	border: none;
	font-size: 14px;
	color: #333;
	cursor: pointer;
}

.sub-dropdown {
	display: none;
	position: absolute;
	/* top: -20px; */
	margin-top: -50px;
	left: 100%;
	background-color: #ffffff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.has-dropdown:hover > .sub-dropdown {
	display: block;
}

.home-main {
	background-color: #fff;
}
.home-main .home-banner {
	width: 100%;
}
.home-main .home-banner img {
	object-fit: contain;
	height: auto;
	width: 100%;
}
.about-section {
	display: flex;
	gap: 16px;
	width: 100%;
	align-items: flex-start;
	justify-content: center;
	text-align: justify;
	margin-block: 40px;
	margin-inline: 0px;
}
.about-section .desc-content {
	font-size: 16px;
	max-width: 600px;
	margin-left: 20px;
}

.about-section .desc-img {
	display: flex;
	justify-content: flex-end;
	width: max-content;
}

.about-section .abt-head {
	font-size: 40px;
	color: var(--color-secondary);
	font-weight: 500;
}

.about-section .desc-img img {
	max-width: 40vw;
	height: auto;
	object-fit: cover;
}
.desc-content {
	overflow: hidden;
	max-height: 500px;
	transition: max-height 0.3s ease;
}

.desc-content.expanded {
	max-height: max-content;
}

.read-more-btn {
	display: block;
	margin-top: 20px;
	padding: 10px 35px;
	background-color: var(--color-secondary);
	color: white;
	border: none;
	font-size: 18px;
	border-radius: 50px;
	cursor: pointer;
}

.read-more-btn:hover {
	background-color: var(--color-secondary);
}

.tab-space {
	margin: 20px;
}

.uspcontainer {
	width: 80%;
	margin: 0 auto;
}

.uspcontainer .usp-head {
	font-size: 42px;
	margin-bottom: 50px;
	text-align: center;
	font-weight: 600;
	color: var(--color-secondary);
}

.uspcontainer .usp-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 48px;
}

.uspcontainer .usp-list .usp-item {
	width: 300px;
	height: 350px;
	text-align: center;
}

.usp-item img {
	width: 300px;
	height: 240px;
	border-radius: 20px;
}

.usp-item .usp-text {
	font-size: 16px;
	font-weight: 600;
	margin-top: 10px;
}

.faq-main {
	background-color: #fff;
	margin-top: 40px;
}

.faq-layout {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding: 20px;
	margin: 0 auto;
}

.faq-layout .question {
	display: flex;
	gap: 20px;
}

.faq-layout .ant-collapse-header {
	padding: 2px !important;
	padding-right: 24px !important;
}

.faq-layout .question p {
	font-size: 18px;
	font-weight: 600;
	margin-right: 24px;
}

.faq-layout .answer {
	margin-inline: 20px;
	padding: 0;
	margin: 0;
}

.faq-layout .question .anticon {
	font-size: 30px;
	color: var(--color-secondary);
}

.faq-content {
	margin-right: 20px;
	width: 60%;
}

.faq-image img {
	width: 300px;
	height: 600px;
}

.faq-main .ant-collapse-expand-icon {
	position: relative;
	left: 96%;
	top: 24px;
}

.faq-main .faq-head {
	font-size: 40px;
	font-weight: 600;
	margin: 0px auto;
	margin-bottom: 20px;
	color: var(--color-secondary);
	width: max-content;
}

.faq-main .ant-collapse-expand-icon svg {
	font-size: 20px;
}

.testimonial-bg-img img {
	width: 100%;
	object-fit: cover;
}

.testimonial-main {
	margin-block: 32px;
}

.testimonial-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-radius: 10px;
	margin: 0 auto;
	margin-top: -250px;
}

.testimonial-container .boot-title {
	font-size: 16px;
	font-weight: 600;
	color: orangered;
}

.testimonial-header {
	font-size: 40px;
	font-weight: bold;
	color: #fff;
	margin-bottom: 40px;
}

.testimonial-card {
	display: flex;
	align-items: center;
	background-color: white;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	width: 100%;
	max-width: 800px;
	position: relative;
}

.testimonial-arrow {
	font-size: 2rem;
	cursor: pointer;
	border: 2px solid var(--color-secondary);
	border-radius: 50%;
	padding: 2px 15px;
	color: var(--color-secondary);
	-webkit-user-select: none;
	user-select: none;
}

.testimonial-arrow .left-arrow {
	position: absolute;
	left: 10px;
}

.testimonial-arrow .right-arrow {
	position: absolute;
	right: 10px;
}

.testimonial-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 0 40px;
	flex: 1;
}

.testimonial-image {
	border-radius: 50%;
	width: 100px;
	height: 100px;
	object-fit: cover;
	margin-bottom: 20px;
}

.testimonial-quote {
	font-size: 1rem;
	margin-bottom: 10px;
	color: black;
	font-weight: 500;
	opacity: 0.7;
}

.testimonial-rating {
	font-size: 1.6rem;
	color: #ddd;
	margin-bottom: 10px;
}

.testimonial-rating span {
	color: gold;
}

.testimonial-name {
	font-size: 1rem;
	color: var(--color-secondary);
	float: right;
	font-weight: 600;
}
.flowchart {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: max-content;
}

.flowchart .flowchart-header {
	font-size: 40px;
	font-weight: bold;
	color: var(--color-secondary);
	margin-block: 40px;
	text-align: center;
}
.flowchart img {
	width: auto;
	height: 80vh;
}

.review-item {
	margin-bottom: 16px;
	padding: 16px;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
}

.review-username {
	font-size: 16px;
	font-weight: 600;
	color: #333;
	margin-bottom: 0px;
}

.review-rating {
	display: flex;
	align-items: center;
}

.star {
	display: inline-block;
	font-size: 1.5rem;
	margin-right: 0.1rem;
	transition: color 0.3s ease;
}

.star.full::before {
	content: "\2605";
	color: #ffbc00;
}

.star.empty::before {
	content: "\2605";
	color: #ddd;
}

.review-comment {
	font-size: 14px;
	color: #666;
	margin-top: 0px;
}

@media (max-width: 600px) {
	.star {
		font-size: 1.2rem;
	}
}

@media (max-width: 768px) {
	.about-section .desc-img img {
		height: 40vh;
		max-width: 90vw;
	}
	.about-section .desc-img {
		margin: 0 auto;
	}
	.about-section {
		flex-direction: column;
	}
	.about-section .desc-content {
		max-width: calc(100vw - 40px);
		font-size: 14px;
	}

	.uspcontainer .usp-head {
		font-size: 32px;
		margin-bottom: 20px;
	}
	.about-section .abt-head {
		font-size: 32px;
	}
	.uspcontainer {
		width: 100%;
	}
	.uspcontainer .usp-list {
		gap: 24px;
	}
	.faq-main {
		margin-top: 24px;
	}
	.faq-layout {
		width: 100%;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}
	.faq-layout .faq-image img {
		height: 50vh;
		width: auto;
	}
	.faq-layout .faq-content .question p {
		font-size: 0.8rem;
	}
	.faq-layout .question .anticon {
		font-size: 24px;
	}
	.faq-main .ant-collapse-expand-icon {
		position: relative;
		left: 96%;
		top: 16px;
	}
	.faq-main .ant-collapse-expand-icon svg {
		font-size: 16px;
	}
	.faq-main .faq-content .answer {
		font-size: 0.8rem;
	}
	.testimonial-container {
		margin-top: -35%;
	}
	.testimonial-header {
		font-size: 32px;
		margin-bottom: 8px;
	}
	.testimonial-arrow {
		font-size: 1rem;
		padding: 2px 10px;
	}
	.testimonial-content {
		padding: 0;
	}
	.testimonial-container .boot-title {
		margin: 0;
		margin-bottom: 10px;
	}
	.testimonial-image {
		width: 80px;
		height: 80px;
		margin-bottom: 0px;
	}
	.testimonial-rating {
		font-size: 1.5rem;
		margin-bottom: 0;
	}
	.testimonial-quote {
		margin: 0;
	}
	.testimonial-name {
		font-size: 0.7rem;
	}

	.faq-content {
		margin-right: 0px;
		width: 100%;
	}
	.flowchart img {
		max-width: 100vw;
		height: auto;
	}
	.flowchart .flowchart-header{
		font-size: 24px;
	}
}
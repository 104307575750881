.user-profile-container {
	display: flex;
	background-color: #f4f4f9;
	padding: 24px;
}

.user-profile-container .sidebar {
	width: 300px;
	background-color: #fff;
	padding: 24px;
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.user-profile-container .sidebar-profile-pic {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	object-fit: cover;
	margin-bottom: 20px;
	border: 1px solid #ddd;
}

.user-profile-container .sidebar h2 {
	margin: 10px 0;
	font-size: 24px;
	color: #333;
	font-weight: 500;
	text-align: center;
}

.user-profile-container .sidebar p {
	color: #666;
	word-break: break-all;
}

.user-profile-container .sidebar-logout-btn {
	background-color: #e53935 !important;
	border-color: #e53935 !important;
}

.user-profile-container .profile-content {
	flex: 1;
	padding: 20px;
	background-color: #fff;
	margin-left: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-profile-container .profile-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.user-profile-container .profile-header h3 {
	margin: 0;
	font-size: 24px;
	color: #333;
	font-weight: 600;
}



.user-profile-container .edit-button {
	background-color: #1890ff;
	color: #fff;
	border: none;
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
}

.user-profile-container .profile-details {
	margin-bottom: 20px;
}

.user-profile-container .profile-info {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
	margin-bottom: 20px;
}

.user-profile-container .profile-field {
	display: flex;
	flex-direction: column;
}

.user-profile-container .profile-field label {
	font-weight: bold;
	margin-bottom: 8px;
	color: #555;
}

.user-profile-container .profile-field div {
	background-color: #f9f9f9;
	padding: 10px;
	border-radius: 4px;
	word-break: break-all;
}

.user-profile-container .add-address-btn {
	display: flex;
	align-items: center;
	background-color: #52c41a;
	border-color: #52c41a;
}

.user-profile-container .add-address-btn .anticon {
	margin-right: 8px;
}

.user-profile-container .ant-modal-header {
	background-color: #1890ff;
	color: #fff;
}

.user-profile-container .ant-modal-body {
	padding: 20px;
}

.user-profile-container .ant-modal-footer {
	text-align: right;
}

.user-profile-container .ant-btn-primary {
	background-color: #1890ff;
	border-color: #1890ff;
}

.user-profile-container .ant-btn-primary:hover {
	background-color: #40a9ff;
	border-color: #40a9ff;
}

.user-profile-container .ant-btn-dashed {
	background-color: #e6f7ff;
	border-color: #1890ff;
}

.user-profile-container .ant-btn-dashed:hover {
	background-color: #d6eaff;
	border-color: #1890ff;
}

.user-profile-container .ant-form-item-label {
	font-weight: bold;
}

.user-profile-container .ant-form-item-control-input {
	border-radius: 4px;
}

.user-profile-container .ant-input {
	border-color: #d9d9d9;
}

.user-profile-container .ant-input:hover,
.user-profile-container .ant-input-focused {
	border-color: #1890ff;
}

@media (max-width: 768px) {
	.user-profile-container {
		flex-direction: column;
		gap: 24px;
	}

	.user-profile-container .sidebar {
		width: 100%;
		border-right: none;
		border-bottom: 1px solid #e8e8e8;
		padding: 24px;
		display: flex;
	}

	.user-profile-container .profile-content {
		width: 100%;
		padding: 24px;
		margin: 0;
	}

	.user-profile-container .profile-header {
		align-items: flex-start;
	}

	.user-profile-container .edit-button {
		margin-top: 10px;
	}
	.user-profile-container .sidebar-profile-pic {
		margin-bottom: 0;
	}
}

@media (max-width: 580px) {
	.user-profile-container .profile-info {
		display: block;
	}
}

.content {
	position: absolute;
	top: 100px;
	left: 225px;
	width: calc(100vw - 225px);
	height: calc(100vh - 100px);
	background: var(--color-bg);
	padding: 25px;
	overflow: auto;
	border-top: 1px solid #e8e8e8;
	border-left: 1px solid #e8e8e8;
	border-top-left-radius: 10px;
}

.ant-layout .ant-layout-sider-zero-width-trigger {
	position: fixed;
	top: 21px;
	background: transparent !important;
	font-size: 25px;
	left: 10px;
	z-index: 5;
	color: black !important;
	left: calc(100vw - 50px);
	margin-left: 5px;
	border-radius: 10px;
}

.menu a {
	text-decoration: none;
}

.head {
	position: fixed;
	top: 0;
	height: 100px;
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: #ffffff !important;
	z-index: 2;
}

.side{
	height: calc(100vh - 80px);
	overflow: hidden;
}

.side .menu {
	font-size: 16px;
	padding: 0px;
	margin-top: 20px;
	font-weight: 500;
	opacity: 0.9;
	height: 100%;
	overflow: auto;
	margin-bottom: 20px;
	background-color: var(--color-secondary);
}

.sidebar-logout-btn{
	background-color: var(--color-secondary);
}

.side .ant-menu-item {
	border: none;
	border-radius:0;
	height: 54px;
	font-size: 14px;
	margin-inline: 0 !important;
	margin: 0;
	width: 100% !important;
	color: #fff;
}

.side .ant-menu-submenu .ant-menu-submenu-title{
	height: 54px;
	border: none;
	font-size: 14px;
	margin-inline: 0 !important;
}

.side .ant-menu-submenu .ant-menu-item {
	border: none;
	background-color: none !important;
	color: black;
	margin-left: 20px;
	font-size: 12px;
	height: 36px;
	width: 100% !important;
	margin: 0 !important;
}


.side .ant-menu-item:hover {
	background: var(--color-bg) !important;
	color: var(--color-secondary) !important;
}


.side .ant-menu-item-selected{
	color: var(--color-secondary);
	background-color: var(--color-bg);
	border-left: 5px solid var(--color-secondary);
	font-weight: 600;
	font-size: 16px;
	opacity: 1;
	margin: 0px !important;
}

.side .ant-menu-submenu .ant-menu-item-selected {
	color: var(--color-secondary);
	background-color: #f5f5f5;
	border-left: 5px solid var(--color-secondary);
	font-weight: 600;
	font-size: 14px;
	opacity: 1;
	margin: 0px !important;
}

.side .ant-menu-submenu .ant-menu-item:hover {
	background: #e8e8e8 !important;
	color: var(--color-secondary) !important;
}

.header-logo a{
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-logo img {
	height: 80px;
	width: auto;
}

.header-buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.header-buttons .anticon svg {
	color: black;
}

.header-buttons img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 1px solid #e8e8e8;
}

.header-buttons img:hover {
	cursor: pointer;
}

.head-icons a:hover {
	color: black;
}

.logoutbtn {
	display: none;
}

.side {
	border-right: #e8e8e8;
	background-color: var(--color-secondary) !important;
}

.ant-dropdown .ant-dropdown-menu{
	width: max-content !important;
}

@media (max-width: 768px) {
	.ant-layout .ant-layout-sider {
		position: absolute;
		top: 80px !important;
		z-index: 5 !important;
		height: calc(100vh - 80px) !important;
		overflow: auto;
	}

	.ant-layout .ant-layout-sider-zero-width-trigger {
		color: black !important;
		font-size: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: transparent;
		cursor: pointer;
		transition: background 0.3s ease;
	}

	.content {
		position: relative;
		top: 80px;
		left: 0px;
		width: 100vw;
		height: calc(100vh - 80px);
		padding: 20px;
	}

	.head .title {
		margin-left: -30px;
		padding: 0;
		font-size: 22px;
		font-weight: 500;
	}
	.head {
		position: fixed;
		top: 0;
		height: 80px;
		width: 100vw;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background: var(--color-secondary);
		z-index: 2;
	}

	.header-logo img {
		height: 60px;
		width: auto;
		margin-top: 0px;
		margin-left: -20px;
	}

	.logoutbtn {
		display: block;
		width: 200px !important;
		margin: 5px;
		border-radius: 7px;
	}

	.logoutbtn button {
		font-size: 16px;
		font-weight: 700;
		height: 37px;
	}

	.header-buttons img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid #e8e8e8;
	}
}

.wishlist-container {
	margin: 20px auto;
	padding: 20px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.wishlist-container .item-details {
	display: flex;
	align-items: center;
}

.wishlist-container .book-list-image {
	width: 60px;
	height: 80px;
	object-fit: cover;
	border-radius: 4px;
	cursor: pointer;
	margin-right: 20px;
}

.wishlist-container .item-title {
	font-size: 16px;
	font-weight: 500;
	color: #333;
}

.wishlist-container .in-stock {
	color: #52c41a;
}

.wishlist-container .out-of-stock {
	color: #f5222d;
}

.wishlist-container .item-actions {
	display: flex;
	justify-content: flex-start;
	gap: 10px;
}

.wishlist-container .item-actions button {
	color: #fff;
	background-color: var(--color-secondary);
	height: 40px;
}

.wishlist-container .item-actions button:first-child {
	background-color: rgb(255, 135, 155);
	color: white;
	height: 40px;
}

.wishlist-container .item-actions button:first-child:hover {
	background-color: rgb(255, 135, 155);
	color: white;
	height: 40px;
}

.wishlist-container .ant-table-cell {
	text-align: center !important;
}

@media (max-width: 768px) {
	.wishlist-container {
		width: calc(100vw - 32px);
	}
	.wishlist-container .ant-table-wrapper {
		width: 100%;
		overflow: auto;
	}
	.wishlist-container .ant-table-wrapper th {
		padding: 0 !important;
	}
}

.order-details-container {
	max-width: 800px;
	margin: 0px auto;
	padding: 20px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.order-details-container .review-container {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	align-items: flex-end;
}

.order-details-container .order-header {
	margin-bottom: 20px;
}

.order-details-container .order-header h3 {
	font-size: 22px;
	font-weight: 500;
	margin: 0;
}

.order-details-container .order-header .order-meta {
	font-size: 14px;
	margin-top: 20px;
	color: #888;
	border-bottom: 1px solid #e0e0e0;
}

.order-details-container .order-meta span {
	display: block;
	margin-bottom: 10px;
}

.order-details-container .order-progress {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	padding: 0 10px;
	position: relative;
	padding-bottom: 10px;
}

.order-details-container .order-progress::before {
	content: "";
	position: absolute;
	top: calc(50% - 4px);
	left: 12%;
	right: 12%;
	height: 4px;
	background-color: #e0e0e0;
	z-index: 0;
}
.assigned:after {
	content: "";
	position: absolute;
	top: calc(50% - 4px);
	left: 12%;
	right: 12%;
	height: 4px;
	background-color: #4caf50;
	z-index: 0;
	width: calc(33% - 6%);
}

.delivered:after {
	content: "";
	position: absolute;
	top: calc(50% - 4px);
	left: 12%;
	right: 12%;
	height: 4px;
	background-color: #4caf50;
	z-index: 0;
	width: calc(66% - 16%);
}

.returned:after {
	content: "";
	position: absolute;
	top: calc(50% - 4px);
	left: 12%;
	right: 12%;
	height: 4px;
	background-color: #4caf50;
	z-index: 0;
	/* width: calc(50% - 5%); */
}
.order-details-container .order-progress-step {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	position: relative;
	width: 25%;
	/* z-index: 9; */
	/* background-color: white; */
}

.order-details-container .order-progress-step .status-dot {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: #bbb;
	margin-bottom: 8px;
	position: relative;
	top: -30px;
}

.order-details-container .order-progress-step.active .status-dot,
.order-details-container .order-progress-step.completed .status-dot {
	background-color: #4caf50;
}

.order-details-container .order-progress-step .status-label {
	font-size: 12px;
	color: #555;
	margin-bottom: 10px;
	height: 80px;
}

.order-details-container .order-progress-step .status-date {
	font-size: 10px;
	color: #888;
}

.order-details-container .order-progress-step.active .status-label,
.order-details-container .order-progress-step.completed .status-label {
	color: #4caf50;
	font-weight: bold;
}

.order-details-container .order-progress-step.active .status-date,
.order-details-container .order-progress-step.completed .status-date {
	color: #4caf50;
}

.order-details-container .books-list {
	margin-bottom: 20px;
	display: block;
}

.order-details-container .book-card {
	display: flex;
	align-items: center;
	padding: 15px;
	margin-bottom: 10px;
	background-color: #fafafa;
}

.order-details-container .book-card img {
	width: 60px;
	height: 80px;
	object-fit: cover;
	margin-right: 15px;
	border-radius: 4px;
}

.order-details-container .book-card .book-details {
	flex-grow: 1;
}

.order-details-container .book-card .book-title {
	font-size: 16px;
	font-weight: bold;
	margin: 0;
	color: #333;
}

.order-details-container .book-card .book-author {
	font-size: 14px;
	margin-top: 5px;
	color: #666;
}

.order-details-container .book-card .book-qty {
	font-size: 14px;
	color: #333;
	text-align: right;
}

.order-details-container .subscription-delivery {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	border-top: 1px solid #e0e0e0;
	padding-top: 20px;
}

.order-details-container .subscription-delivery .section-title {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 10px;
	color: #333;
}

.order-details-container .subscription-delivery .section-content {
	font-size: 14px;
	color: #666;
}

.order-details-container .return-button {
	background-color: #4caf50;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 20px;
}

.order-details-container .return-button:hover {
	background-color: #45a049;
}

.user-orders-list {
	max-width: 800px;
	margin: 10px auto;
	background-color: #ffffff;
	padding: 20px;
	border-radius: 10px;
}

.user-orders-list .user-orders-list-item {
	width: 100%;
	border-radius: 5px;
	margin-bottom: 16px;
	padding: 16px;
	cursor: "pointer";
}

.user-orders-list .user-orders-list-item:hover {
	background-color: #f9f9f9;
}

.user-orders-list .orders-book-details {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	width: max-content;
}

.user-orders-list .orders-book-details img {
	width: 50px;
	height: 75px;
	object-fit: cover;
	margin-right: 10px;
	border-radius: 3px;
}

.user-orders-list .books-list {
	display: flex;
}

.user-orders-list .orders-book-details li {
	list-style-type: none;
}

.user-orders-list h3 {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 10px;
}

.user-orders-list strong {
	font-weight: 600;
}

.user-orders-list p {
	margin: 5px 0;
}

.admin-od {
	display: flex;
}

.admin-od .right-sec {
	background-color: white;
	padding: 20px;
	margin: 20px;
	border-radius: 10px;
	min-width: 350px;
	display: flex;
	align-items: flex-start;
	gap: 10px;
	flex-direction: column;
	height: max-content;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.admin-od .right-sec div {
	font-size: 14px;
	text-transform: capitalize;
}

.admin-od .right-sec button {
	background-color: #4caf50;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: pointer;
	opacity: 0.8;
}

.admin-od .right-sec .ant-btn:hover {
	background-color: #4caf50;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: pointer;
	opacity: 1;
}

.user-orders-list {
	width: 100%;
}

.user-orders-list-item {
	border-bottom: 1px solid #f0f0f0;
	padding: 16px 0;
}

.order-content {
	display: flex;
}

.order-image {
	flex: 0 0 120px;
	margin-right: 16px;
}

.order-book-image {
	width: 100%;
	border-radius: 5px;
}

.order-details {
	flex: 1;
}

.order-actions {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.view-order-button {
	background-color: #1890ff;
	border-color: #1890ff;
	color: #fff;
	margin-bottom: 10px;
	padding: 6px 12px;
	border-radius: 4px;
	cursor: pointer;
}

@media(max-width:768px){
	.order-content{
		flex-direction: column;
	}
	.order-image{
		flex: 0;
	}
	.user-orders-list h3{
		font-size: 16px;
	}
	.order-book-image{
		width: 80px;
	}
	.order-details-container{
		max-width: calc(100vw - 32px);
	}
}
.user-layout-content {
	width: 100%;
	height: calc(100vh - 120px);
	display: flex;
	flex-direction: column;
	position: relative;
	top: 120px;
	background: #f5f6f8;
	/* border-top: 1px solid #e8e8e8; */
	overflow: auto;
}

.user-layout {
	width: 100% !important;
}
.user-layout-head {
	position: fixed;
	top: 0;
	height: 120px;
	width: 100%;
	display: flex;
	border-top: 18px solid #0184b8;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: #ffffff !important;
	z-index: 2;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 3px;
}

.user-layout-head.ant-layout-header {
	padding: 0 !important;
}
/* :where(.css-dev-only-do-not-override-m4timi).ant-menu-light .ant-menu-item-selected{
    color:black;
}*/
.ant-menu-light .ant-menu-item::hover {
	color: black;
	border-bottom: 0px;
}
.ant-menu-light .ant-menu-item-selected {
	color: #0184b8;
	background-color: black;
	border-bottom: 0px;
}
.ant-menu-light .ant-menu-item-selected::hover {
	color: #0184b8;
	background-color: black;
}
:where(.css-dev-only-do-not-override-m4timi).ant-menu-light.ant-menu-horizontal
	> .ant-menu-item:hover {
	border-bottom: 0px;
}
.user-layout-head .user-layout-menu .ant-menu-item:hover::after {
	background: #0184b8 !important;
	border-color: #0184b8 !important;
	font-weight: 600;
}
.user-layout-head .user-layout-menu .ant-menu-item-selected a {
	color: #0184b8 !important;
}
.user-layout-head .user-layout-menu .ant-menu-item-selected a::before {
	color: #0184b8 !important;
}
.ant-menu-overflow-item .ant-menu-item:hover {
	background-color: #0184b8;
}
.user-layout-head .user-layout-header-logo img {
	width: auto;
	margin-left: 54px;
	height: 80px;
}

.user-layout-head .user-layout-header-logo a {
	display: flex;
	align-items: center;
	justify-content: center;
}

.user-layout-head
	.ant-menu-light.ant-menu-horizontal
	> .ant-menu-item-selected::after {
	border-bottom-width: 0 !important;
	border: none;
}

.user-layout-head .user-layout-menu .ant-menu-item-selected svg {
	color: var(--color-primary);
}

.user-layout-head .user-layout-menu a {
	font-size: 16px;
	color: #000;
}

.user-layout-head .user-layout-menu .ant-menu-item-selected a {
	color: var(--color-primary);
	font-weight: 600;
}

.user-layout-mobile-menu-button {
	display: none;
}

.user-layout-menu {
	width: calc(100vw - 250px);
	display: flex;
	margin-right: 45px;
	justify-content: flex-end;
	border-bottom: none;
}

@media (max-width: 768px) {
	.user-layout-menu {
		display: none;
	}
	.user-layout-mobile-menu-button {
		display: block;
	}
}




.footer {
	background-color: #fff5e6;
	padding: 32px;
	width: calc(100% - 3px);
	position: relative;
	bottom: 0;
}

.footer-top {
	background-color: #fff5e6;
}

.footer-middle {
	margin-left: 50px;
}

.footer-content {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr;
	gap: 50px;
	padding: 32px 0;
	margin-bottom: 0px;
}

.footer .logo {
	width: 150px;
}

.footer .footer-description {
	margin-top: 10px;
	font-size: 12px;
	text-align: justify;
}

.footer .social-media {
	margin-top: 10px;
}

.footer .social-icon {
	margin-right: 10px;
	font-size: 24px;
	color: var(--color-primary);
}

.footer .quick-links {
	list-style: circle;
	padding: 0;
	margin-left: 20px;
}

.footer .terms-contiditons-links {
	display: flex;
	gap: 16px;
	list-style: none;
	padding: 0;
}
.footer .terms-contiditons-links li {
	border-right: 1px solid black;
	padding-right: 16px;
}

.footer .terms-contiditons-links li:last-child {
	border-right: none;
}

.footer .terms-contiditons-links a {
	text-decoration: underline;
	color: #000;
}

.footer .quick-links li {
	margin: 5px 0;
}

.footer .quick-links a {
	text-decoration: none;
	color: #000;
}

.footer .newsletter {
	margin-top: 10px;
}

.footer .newsletter h4 {
	margin-bottom: 10px;
}

.footer .newsletter form {
	display: flex;
	flex-direction: column;
}

.footer .newsletter input {
	padding: 5px;
	margin-bottom: 10px;
}

.footer .newsletter button {
	padding: 5px;
	background-color: var(--color-secondary);
	color: #fff;
	border: none;
	cursor: pointer;
}

.footer-bottom {
	background-color: #333;
	color: #fff;
	text-align: center;
	padding: 10px;
	margin: -35px;
	margin-top: 0px;
	font-size: 14px;
	/* display: flex;
	justify-content: space-around;
	align-items: center; */
}

.footer-bottom .copyright span {
	color: var(--color-secondary);
}

.footer-bottom a {
	color: var(--color-secondary);
	text-decoration: none;
}

.footer-bottom .gads {
	text-align: center;
	font-size: 10px;
}

@media (max-width: 768px) {
	.footer-content {
		display: block;
		padding: 16px 0;
		margin-bottom: 32px;
	}
	.footer-middle {
		margin-left: 0;
	}
}

@media (max-width: 580px) {
	.footer-bottom .copyright{
		font-size: 12px;
	}
	.footer-bottom .gads {
		text-align: center;
		font-size: 10px;
	}
}

.main-div {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.newsletter-form {
	background-color: #fff;
	padding: 40px;
	border-radius: 12px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	max-width: 1200px;
	width: 100%;
	transition: all 0.3s ease-in-out;
}


.newsletter-title {
	text-align: center;
	font-size: 24px;
	color: #333;
	margin-bottom: 30px;
	font-weight: 600;
	letter-spacing: 1px;
}

.main-div .ant-form-item-label > label {
	font-weight: 500;
	color: #555;
}

.main-div .ant-input,
.main-div .ant-input-textarea {
	padding: 10px;
	border-radius: 8px;
	border: 1px solid #d9d9d9;
	font-size: 16px;
	width: 400px;
}

.main-div .ant-input:focus,
.main-div .ant-input-textarea:focus {
	border-color: #1890ff;
	box-shadow: 0 0 6px rgba(24, 144, 255, 0.2);
}

.main-div .ant-input-textarea {
	min-height: 120px;
}

.main-div .newsletter-submit-btn {
	width: 100%;
	background-color: #1890ff;
	border-color: #1890ff;
	height: 48px;
	font-size: 18px;
	font-weight: 500;
	border-radius: 8px;
}

.main-div .newsletter-submit-btn:hover {
	background-color: #40a9ff;
	border-color: #40a9ff;
}

.main-div .newsletter-submit-btn:focus {
	background-color: #096dd9;
	border-color: #096dd9;
	box-shadow: 0 0 6px rgba(24, 144, 255, 0.4);
}

@media (max-width: 768px) {
	.main-div .newsletter-form {
		padding: 30px;
	}

	.main-div .newsletter-title {
		font-size: 22px;
	}

	.main-div .ant-input,
	.main-div .ant-input-textarea {
		font-size: 14px;
	}

	.main-div .newsletter-submit-btn {
		font-size: 16px;
		height: 45px;
	}
}

@media (max-width: 480px) {
	.main-div .newsletter-form {
		padding: 20px;
	}

	.main-div .newsletter-title {
		font-size: 20px;
	}

	.main-div .newsletter-submit-btn {
		font-size: 14px;
		height: 42px;
	}
}

.display-act .section-1 {
	display: flex;
	justify-content: center;
	align-items: baseline;
	margin: 20px;
}

.user-activity-main {
	/* width: 100%; */
	background-color: #fff;
}

.display-act {
	margin: 0 auto;
}

.display-act .section-1 h1 {
	font-size: 40px;
	font-weight: 500;
	/* color: var(--color-primary); */
}

.display-act .section-1 .filter-buttons {
	display: flex;
	gap: 10px;
}

.banner {
	/* height: 300px; */
	width: 100%;
}

.banner img {
	/* height: 300px; */
	object-fit: cover;
	width: -webkit-fill-available;
}

.display-act .user-activity-main {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	border-radius: 20px;
	margin: 32px;
	justify-content: center;
}

.display-act .user-activity-main .act-date-cont {
	font-weight: 400;
	color: #4f4f4f;
	width: 80px;
	display: flex;
    flex-direction: column;
    align-items: center;
}

.display-act .user-activity-main .act-date-cont .month {
	font-size: 12px;
}

.display-act .user-activity-main .act-date-cont .day {
	font-size: 28px;
	color: #0184B8;
	font-weight: 600;
	margin: 5px;
}

.display-act .user-activity-main .act-date-cont .year {
	font-size: 10px;
}

.display-act .user-activity-main .activity-details {
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
	gap: 20px;
	margin-inline: 20px;
}

.display-act .user-activity-main img {
	height: 200px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.display-act .user-activity-main .activity-card {
	background-color: #ffffff;
	display: flex;
	max-height: 420px;
    width: 100%;
    max-width: 340px;
    height: 100%;
	flex-direction: column;
	margin-bottom: 20px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


.display-act .user-activity-main .activity-card:hover{
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.display-act .user-activity-main .activity-card .act-name {
	font-size: 16px;
	text-align: left;
	margin: 0;
}

.display-act .user-activity-main .activity-card .location {
	font-size: 10px;
	opacity: 0.7;
	text-align: left;
	margin-top: 0px;
}

.display-act .user-activity-main .activity-card .act-desc {
	margin-top: -5px;
	font-size: 12px;
	line-height: 1.5;
	word-spacing: 1;
	word-break: break-all;
}

@media (max-width: 1000px) {
	.display-act .user-activity-main {
		justify-content: center;
	}
}

.signup-cont {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px 20px;
	min-height: 100vh;
	background-color: #f0f2f5;
	box-sizing: border-box;
}

.signup {
	background: #fff;
	padding: 40px;
	border-radius: 8px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	max-width: 800px;
	width: 100%;
	box-sizing: border-box;
}

.signup-form {
	width: 100%;
}

.signup-img {
	max-width: 400px;
	width: 100%;
	margin-left: 40px;
	object-fit: contain;
}

@media (max-width: 768px) {
	.signup-cont {
		flex-direction: column-reverse;
		gap: 24px;
		padding: 20px;
	}

	.signup-img {
		margin-left: 0;
		margin-top: 20px;
		max-width: 200px;
	}
}

@media (max-width: 768px) {
	.d-grid-2,
	.signup-name {
		grid-template-columns: 1fr;
	}

	.plans-table th,
	.plans-table td {
		padding: 8px 12px;
	}

	.signup-cont {
		padding: 20px;
	}

	.signup-img {
		max-width: 100%;
		margin-top: 20px;
	}
}

.d-grid-2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
}

.signup-name {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
}

.steps-action {
	margin-top: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.signup-submit {
	display: flex;
	justify-content: flex-end;
	align-items: baseline;
}

.previous,
.next,
.submit {
	margin: 0 8px;
}

.plan-type-filter {
	margin-bottom: 24px;
}

.plan-type-filter .ant-radio-group {
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
}

.plans-table {
	overflow-x: auto;
	margin-top: 16px;
}

.plans-table table {
	width: 100%;
	border-collapse: collapse;
}

.plans-table th,
.plans-table td {
	padding: 12px 16px;
	text-align: left;
	border-bottom: 1px solid #f0f0f0;
}

.plans-table th {
	background-color: #fafafa;
	font-weight: 500;
	color: #595959;
}

.plans-table tr:hover {
	background-color: #f5f5f5;
}

.current-plan {
	background-color: #e6f7ff;
}

.current-plan td {
	font-weight: 500;
	color: #1890ff;
}

.coupon-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
}

.coupon-select {
	width: 70%;
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #d9d9d9;
}

.coupon-select .ant-select-selector{
	border: none !important;
}

.apply-coupon-btn {
	width: 25%;
	background-color: #1890ff;
	color: white;
	padding: 8px 16px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	margin-left: 10px;
}

.apply-coupon-btn:hover {
	background-color: #40a9ff;
}

.plan-details {
	background-color: #f4f6f8;
	padding: 20px;
	border: 1px solid #d9e0e7;
	border-radius: 8px;
	margin-top: 20px;
}

.plan-item {
	font-size: 16px;
	margin: 10px 0;
	color: #333;
	font-weight: 400;
}

.plan-item span {
	font-weight: 400;
	color: #000;
}

.coupon-form-item {
	margin-bottom: 20px;
}

@media (max-width: 768px) {
	.coupon-row {
		flex-direction: column;
		align-items: stretch;
	}

	.coupon-select,
	.apply-coupon-btn {
		width: 100%;
		margin: 0 0 10px 0;
	}

	.apply-coupon-btn {
		margin-left: 0;
	}

	.plan-details {
		padding: 15px;
	}
	.d-grid-2{
		display: block;
	}
}

.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.steps-action .ant-btn {
	min-width: 100px;
}

.plans-table tr:hover {
	transition: background-color 0.3s ease;
}

.signup-form .ant-form-item {
	margin-bottom: 16px;
}

.activity-img1 {
	width: 100%;
	height: auto;
}
.act-desc {
	text-align: justify;
	white-space: break-spaces;
	word-break: auto-phrase;
	word-wrap: break-word;
	font-weight: 400;
	line-height: 26px;
	font-size: 14px;
	color: #4f4f4f;
}
.act-cont {
	/* width: 65%; */
}
.ant-image .ant-image-img {
	object-fit: cover;
	height: 250px;
}
.ant-image .ant-image-mask {
	background: rgba(0, 0, 0, 0);
}
.img-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	column-gap: 30px;
	row-gap: 30px;
	flex-direction: row;
	flex-wrap: wrap;
}
.actimg {
	margin: 10px;
}
.act-loc {
	top: 0px;
	left: 50%;
	align-items: center;
	justify-content: center;
}
.activity-full-cont {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 50px;
}
.date {
	font-size: 16px;
	line-height: 20px;
	color: #2e363f;

	font-weight: 700;
}
.date-cont {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #4f4f4f;
	margin-bottom: 30px;
}
.back-btn {
	cursor: pointer;
}

.admin-act-detail {
	background-color: #fff !important;
	border-radius: 8px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-act-detail .detail-activty-card .activity-img {
	max-width: 40vw !important;
}

.actdeletebtn {
	background-color: var(--color-primary);
	color: #fff;
	height: 40px;
	font-size: 16px;
	width: 100px;
}
